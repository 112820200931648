import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["notice"];

  hide() {
    this.noticeTargets.forEach((notice) => {
      if (notice.id == "user-notice") {
        notice.classList.add("hidden");
      } else if (notice.id == "new-notice") {
        notice.classList.add("hidden");
      }
    });
  }
}
