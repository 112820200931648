/*
this controller is used for the interactive
zoomable/pannable maps of all the berths in a marina.
when it connects, it iterates over the
berths and attaches a new berth_path_controller
to the layers in the svg representing the shape
of each individual berth. consequently, you
only need to actually put the berth_map_controller
in the HTML, and the berth_path_controllers get
automatically added programmatically :)
*/
/* eslint-disable no-unused-expressions */
import panzoom from "svg-pan-zoom";
import Flatpickr from "stimulus-flatpickr";
import List from "list.js";
import tippy from "tippy.js";
import memoize from "memoizee";
import { get } from "@rails/request.js";

const NODE_ENV = import.meta.env.MODE || "production";

const CACHE_AGE = 5 * 60 * 1000; // 5 minutes in ms

const PANZOOM_OPTIONS = Object.freeze({
  minZoom: 1,
  maxZoom: 10,
  contain: true,
  fit: false,
  center: true,
});

const FLATPICKR_OPTIONS = Object.freeze({
  formatDate: (date) => {
    const dateInstance = new Date(date);
    return dateInstance.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  },
});

const LIST_JS_OPTIONS = {
  valueNames: ["description"],
};

const PONTOON_CLASSNAME = "fill-current opacity-0 pointer-events-none";

// const reduceMotion = window.matchMedia("(prefers-reduced-motion: reduce)")

const calculateScale = (el, sizes) => {
  const bounds = el.getBBox();

  const dw = sizes.width / bounds.width / sizes.realZoom;
  const dh = sizes.height / bounds.height / sizes.realZoom;
  const scale = Math.min(dw, dh);

  const x = -1 * (bounds.x * sizes.realZoom);
  const y = -1 * (bounds.y * sizes.realZoom);

  if (NODE_ENV === "development") {
    /* eslint-disable no-console */
    console.debug("moving to pontoon", {
      x,
      y,
      scale,
      dw,
      dh,
      sizes,
      bounds,
    });
    /* eslint-enable no-console */
  }

  return [x, y, scale];
};

const dateToString = (date) => {
  // accommodate for timezone difference and format as YYYY-MM-DD
  const offset = date.getTimezoneOffset();
  const yourDate = new Date(date.getTime() - offset * 60 * 1000);
  return yourDate.toISOString().split("T")[0];
};

export default class extends Flatpickr {
  static targets = [
    "map",
    "help",
    "search",
    "searchOptions",
    "boat",
    "pontoonSelect",
    "spinner",
  ];

  static values = {
    date: String,
  };

  async connect() {
    this.panzoomInstance = panzoom(this.mapTarget, {
      ...PANZOOM_OPTIONS,
      beforePan: this.handlePan,
    });
    if (NODE_ENV === "development") window.panzoom = this.panzoomInstance;
    this.mapTarget.classList.add("cursor-move");
    window.addEventListener("resize", this.handleResize);

    // include the stimlus-flatpickr library
    this.config = {
      ...this.config,
      ...FLATPICKR_OPTIONS,
    };
    super.connect();
    // ^ initializes a flatpickr instance called `this.fp`
    // rename some of the flatpickr-stimulus default variables so their names aren't super vague
    this.flatpickrInstance = this.fp;
    this.dateInputTarget = this.inputTarget;

    this.tippyInstance = tippy(this.helpTarget, {
      content:
        "This interactive map allows you to navigate by clicking or tapping and dragging. To zoom in or out, scroll or pinch. For more information about a specific berth, simply click or tap on it.",
      placement: "left",
    });

    this.listInstance = new List("search_berths", LIST_JS_OPTIONS);

    this.dateValue = new URLSearchParams(window.location.search).get("date")
      || dateToString(new Date());
    this.flatpickrInstance.setDate(this.dateValue);
    await this.fetchData(this.parsedDateValue);
    this.panzoomInstance && this.panzoomInstance.resize();
    // makes sure it is interactive from page load
    // there's maybe some bug with the lib, so this is needed

    setTimeout(() => {
      this.zoomToPontoon("default");
      return this.mapTarget.classList.remove("opacity-0");
    }, 100);
  }

  disconnect() {
    this.panzoomInstance && this.panzoomInstance.destroy();
    window.removeEventListener("resize", this.handleResize);
    this.tippyInstance && this.tippyInstance.unmount();
    super.disconnect();
  }

  handleDateChange = async (event) => {
    const date = event.currentTarget.value
      ? new Date(event.currentTarget.value)
      : new Date();
    this.dateValue = dateToString(date);
    await this.fetchData(date);
  };

  handleNextDate = async (event) => {
    event.preventDefault();
    const date = this.dateInputTarget.value
      ? new Date(this.dateInputTarget.value)
      : new Date();
    const newDate = date.setDate(date.getDate() + 1);
    this.flatpickrInstance.setDate(newDate);
    this.dateValue = dateToString(new Date(newDate));
    await this.fetchData(date);
  };

  handlePreviousDate = async (event) => {
    event.preventDefault();
    const date = this.dateInputTarget.value
      ? new Date(this.dateInputTarget.value)
      : new Date();
    const newDate = new Date(date);
    newDate.setDate(date.getDate() - 1);

    this.flatpickrInstance.setDate(newDate);
    this.dateValue = dateToString(newDate);
    await this.fetchData(newDate);
  };

  handlePontoonChange = async (event) => {
    // scroll and zoom to fit the rectangle for the pontoon in the view area
    const pontoon = event.currentTarget.value;
    if (!pontoon) return false;

    this.zoomToPontoon(pontoon);
    return true;
  };

  handleResize = () => {
    this.panzoomInstance && this.panzoomInstance.resize();
  };

  handlePan = (oldPan, newPan) => {
    try {
      const sizes = this.panzoomInstance.getSizes();
      const leftLimit = -1 * sizes.viewBox.width * sizes.realZoom + sizes.width;
      const rightLimit = 0;
      const topLimit = -1 * sizes.viewBox.height * sizes.realZoom + sizes.height;
      const bottomLimit = 0;

      const customPan = {};
      customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x));
      customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y));

      this.pontoonSelectTarget.value = "";

      return customPan;
    } catch (error) {
      if (NODE_ENV === "development") {
        /* eslint-disable no-console */
        console.error(
          "An error was encountered while panning. We are ignoring this error so the map doesn't get stuck, but you may wish to resolve this.",
          error,
        );
        /* eslint-enable no-console */
      }
      return newPan;
    }
  };

  handleZoomIn = () => {
    this.panzoomInstance.zoomIn();
  };

  handleZoomOut = () => {
    this.panzoomInstance.zoomOut();
  };

  // private

  async fetchData(date = new Date()) {
    this.spinnerTarget.classList.remove("hidden");
    const berths = await this.fetchBerths();
    berths.map(this.linkBerthToMap);

    this.clearMapCalendarEntries();
    const mapCalendarEntries = await this.fetchMapCalendarEntries(date);
    mapCalendarEntries.map(this.linkMapCalendarEntryToMap);

    this.linkDatesToMap(date);

    const pontoons = [...(await this.fetchPontoons()), "default"];
    pontoons.map(this.linkPontoonToMap);
    this.spinnerTarget.classList.add("hidden");
  }

  fetchBerths = memoize(async () => {
    const response = await get("/berths", {
      responseKind: "json",
    });
    return response.ok ? response.json : [];
  }, { maxAge: CACHE_AGE });

  fetchMapCalendarEntries = memoize(async (date = new Date()) => {
    const response = await get(`/berth_map_slots?date=${dateToString(date)}`, {
      responseKind: "json",
    });
    return response.ok ? response.json : [];
  }, { normalizer: ([date]) => date.toISOString(), length: 1, maxAge: CACHE_AGE });

  fetchPontoons = memoize(async () => {
    const response = await get("/pontoons", {
      responseKind: "json",
    });
    return response.ok ? response.json : [];
  }, { maxAge: CACHE_AGE });

  linkBerthToMap = (berth) => {
    // initialize a new berth_path_controller for that path
    const layerID = `Berth-${berth.unique_identifier}`;
    const el = this.element.querySelector(
      `[id=${layerID} i], [id=${layerID}-flipped i]`,
    );

    if (!el) {
      if (NODE_ENV === "development") {
        /* eslint-disable no-console */
        console.warn(
          `Could not find a map element for berth ${berth?.unique_identifier}`,
        );
        /* eslint-enable no-console */
      }
      return false;
    }

    if (NODE_ENV === "development") {
      /* eslint-disable no-console */
      console.debug(
        `Connecting berth ${berth.unique_identifier} to the DOM`,
        el,
      );
      /* eslint-enable no-console */
    }

    el.dataset.controller = "berth-path";
    el.dataset.berthPathBerthValue = JSON.stringify(berth);
    return true;
  };

  clearMapCalendarEntries = () => {
    const elements = this.element.querySelectorAll(
      "[data-controller=berth-path]",
    );

    elements.forEach((e) => {
      e.dataset.berthPathMapCalendarEntryValue = JSON.stringify({});
    });
  };

  linkMapCalendarEntryToMap = (mapCalendarEntry) => {
    // initialize a new mapCalendarEntry_path_controller for that path
    const layerID = `berth-${mapCalendarEntry.unique_identifier}`;
    const el = this.element.querySelector(
      `[id=${layerID} i], [id=${layerID}-flipped i]`,
    );

    if (el) {
      el.dataset.berthPathMapCalendarEntryValue = JSON.stringify(mapCalendarEntry);
    }
  };

  linkDatesToMap = (date = new Date()) => {
    const elements = this.element.querySelectorAll(
      "[data-controller=berth-path]",
    );

    elements.forEach((e) => {
      e.dataset.berthPathDateValue = dateToString(date);
    });
  };

  linkPontoonToMap = (pontoon) => {
    // pontoon is a string like "a" in lowercase
    const layerID = `[id=Pontoon-${pontoon} i] > :is(rect, path)`;
    const el = this.element.querySelector(layerID);

    if (!el) {
      if (NODE_ENV === "development") {
        /* eslint-disable no-console */
        console.warn(
          `Could not find a map element for pontoon ${pontoon?.toUpperCase()}`,
        );
        /* eslint-enable no-console */
      }
      return false;
    }

    if (NODE_ENV === "development") {
      /* eslint-disable no-console */
      console.debug(
        `Connecting pontoon ${pontoon.toUpperCase()} to the DOM`,
        el,
      );
      /* eslint-enable no-console */
    }

    el.dataset.pontoon = pontoon;
    el.classList = PONTOON_CLASSNAME;
    return true;
  };

  /* eslint-disable class-methods-use-this */
  unselectAllBerths = () => {
    const berthEls = document.querySelectorAll("[data-controller=berth-path]");
    berthEls.forEach((e) => {
      e.dataset.berthPathSelectedValue = false;
    });
  };
  /* eslint-enable class-methods-use-this */

  selectBerth = (event) => {
    event.preventDefault();

    this.unselectAllBerths();

    const berthID = event.currentTarget.dataset.id;
    const berthIdentifier = event.currentTarget.dataset.identifier;

    const sidebarURL = `/berths/${berthID}/sidebar?date=${this.dateValue}`;
    this.sidebarTarget.src = sidebarURL;

    const layerID = `Berth-${berthIdentifier}`;
    const el = this.element.querySelector(`[id^=${layerID} i]`);

    if (!el) {
      throw new Error(
        `Could not find a map element for the berth ${berthIdentifier?.toUpperCase()}`,
      );
    }

    el.dataset.berthPathSelectedValue = true;

    this.zoomToBerth(el);
  };

  zoomToPontoon(pontoon) {
    const el = this.element.querySelector(`[data-pontoon='${pontoon}']`);
    // `el` will be a `<rect>` element

    if (!el || !this.panzoomInstance) {
      if (NODE_ENV === "development") {
        console.error(
          pontoon === "default"
            ? "There's no default pontoon for this map (in the SVG file). To add a default zoom box, please add a rectangle to the map and call it 'Pontoon-default'."
            : `Could not find a map element for pontoon ${pontoon?.toUpperCase()}`,
        );
      }
      return false;
    }

    // Uncomment the lines below for debugging purposes to see where the pontoon is actually located
    // if (NODE_ENV === "development") {
    //   document.querySelectorAll("[data-pontoon]").forEach((otherPontoon) => {
    //     otherPontoon.style = "opacity: 0;";
    //   });
    //   el.style = "fill: purple; opacity: 0.5;";
    // }

    // Firefox compatibility fix
    el.setAttribute("display", "block");
    el.parentElement.setAttribute("display", "block");

    if (NODE_ENV === "development") {
      console.debug(
        `Zooming to pontoon ${pontoon.toUpperCase()}`,
        {
          element: el,
          panzoomInstance: this.panzoomInstance,
        },
      );
    }

    this.panzoomInstance.disablePan();
    this.panzoomInstance.disableZoom();
    this.panzoomInstance.reset();
    this.panzoomInstance.updateBBox();

    let sizes = this.panzoomInstance.getSizes();

    // calculate the vertical and horizontal scale factors and then take the minimum required one
    const [x, y, scale] = calculateScale(el, sizes);

    if (NODE_ENV === "development") {
      console.debug(
        `Calculated scale for pontoon ${pontoon.toUpperCase()}`,
        {
          x,
          y,
          scale,
          sizes,
          elementBounds: el.getBBox(),
        },
      );
    }

    this.panzoomInstance.pan({ x, y });
    this.panzoomInstance.zoom(scale);
    this.panzoomInstance.pan({ x: x * scale, y: y * scale });
    this.panzoomInstance.updateBBox();

    // finally center on the box
    const bounds = el.getBBox();
    sizes = this.panzoomInstance.getSizes();
    const [x2, y2] = [
      -1 * ((bounds.x + bounds.width / 2) * sizes.realZoom) + sizes.width / 2,
      -1 * ((bounds.y + bounds.height / 2) * sizes.realZoom) + sizes.height / 2,
    ];

    if (NODE_ENV === "development") {
      console.debug(
        `Final pan and zoom positions for pontoon ${pontoon.toUpperCase()}`,
        {
          x2,
          y2,
          finalSizes: sizes,
        },
      );
    }

    this.panzoomInstance.pan({ x: x2, y: y2 });
    this.panzoomInstance.updateBBox();

    this.panzoomInstance.enablePan();
    this.panzoomInstance.enableZoom();

    if (pontoon !== "default") this.pontoonSelectTarget.value = pontoon; // gets reset during the pan action, so prevent that from happening
    return true;
  }

  zoomToBerth(el) {
    this.panzoomInstance.disablePan();
    this.panzoomInstance.disableZoom();
    this.panzoomInstance.reset();
    this.panzoomInstance.updateBBox();

    this.panzoomInstance.zoom(2);
    // can be changed to any value we want for zoom when focused on a berth

    let sizes = this.panzoomInstance.getSizes();

    const bounds = el.getBBox();
    sizes = this.panzoomInstance.getSizes();
    const [x2, y2] = [
      -1 * ((bounds.x + bounds.width / 2) * sizes.realZoom) + sizes.width / 2,
      -1 * ((bounds.y + bounds.height / 2) * sizes.realZoom) + sizes.height / 2,
    ];
    this.panzoomInstance.pan({ x: x2, y: y2 });
    this.panzoomInstance.updateBBox();

    this.panzoomInstance.enablePan();
    this.panzoomInstance.enableZoom();
  }

  /* eslint-disable class-methods-use-this */
  dateValueChanged(value) {
    // sync change to url params
    if (!value) return;

    const url = new URL(window.location.href);
    url.searchParams.set("date", value);
    window.history.pushState(null, document.title, url.toString());
  }

  get sidebarTarget() {
    /*
    due to the slightly unorthodox structure with
    these controllers attaching programmatically,
    there wasn't a good way to fit this target in
    the stimulus controller, so I've just opted
    for a pagewide querySelector instead
    */
    return document.querySelector("#sidebar");
  }
  /* eslint-enable class-methods-use-this */

  get parsedDateValue() {
    return new Date(this.dateValue);
  }
}
/* eslint-enable no-unused-expressions */
