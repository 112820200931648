// TODO: [TASK-1281] replace @rails/ujs” with https://github.com/rails/request.js
import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import { Turbo } from "@hotwired/turbo-rails";
// Import Alpine.js and its adapter for Turbo Drive.
import "alpine-turbo-drive-adapter";
import Alpine from "alpinejs";
import "../components/alpine_components";

// Lazy loading for images.
import "lazysizes";

// Import external libraries required globally.
import "trix";
import "@rails/actiontext";
import "fslightbox";
import "../controllers/index";
// TODO: channel import not working
// import "../channels/index";
import { initMapboxIndex, initMapboxShow } from "../plugins/init_mapbox";

// Import plugins using dynamic imports if they are not needed immediately.
const plugins = {
  Tippy: () => import("../components/tippy").then((m) => m.Tippy),
};

// Initialize Rails UJS and ActiveStorage, and set global variables.
ActiveStorage.start();
window.Rails = Rails;
window.Alpine = Alpine;
Alpine.start();

// https://github.com/hotwired/turbo-rails/pull/367#issuecomment-1934729149
// https://github.com/corsego/151-dialog-turbo-modals/commit/c2cecbae1da1e327f8ad91c403531a8ec738a3d1#diff-732faaf698b62ae82d14ed1df9d1505d973a27281fe5d887a935d370144343b4
// turbo_stream.action(:redirect, comments_path)
Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

// Setup event listeners for Turbo Drive and custom events.
document.addEventListener("turbo:load", () => {
  initMapboxIndex();
  initMapboxShow();
  plugins.Tippy?.();
});

document.addEventListener("trix-file-accept", (e) => {
  e.preventDefault();
});
